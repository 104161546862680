import axios from 'axios'

import { AppConfig } from '@/configs/app.config'

export class DomainRequest {
  static async getDomain(params) {
    return await axios.get(`${AppConfig.backUrl}/api/domain/get`, {
      params,
      headers: { 'Content-Type': 'application/json' },
    })
  }
}
