export class AppConfig {
  static get baseUrl() {
    return process.env.VUE_APP_BASE_URL
  }
  static get frontUrl() {
    return process.env.VUE_APP_FRONT_URL + process.env.VUE_APP_PUBLIC_PATH
  }
  static get backUrl() {
    return process.env.VUE_APP_BACK_URL
  }
  static get redirectUrlToOldSite() {
    return process.env.VUE_APP_REDIRECT_URL
  }
  static get redirectUrl() {
    return process.env.VUE_APP_REDIRECT_URL
  }
  static get keycloakUrl() {
    return process.env.VUE_APP_KEYCLOAK_URL
  }
  static get blogUrl() {
    return process.env.VUE_APP_BLOG_URL
  }
}
