import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { useDealFinishStore } from '@/store/pages/deal-finish'

const toast = useToast()

export const useNotificationStore = defineStore('notif', {
  state: () => ({
    step: null,
    error: null,
    success: null,
  }),
  actions: {
    async subDealFinish(token) {
      try {
        const self = this
        const host = localStorage.getItem('domain_api')
        await fetchEventSource(`${host ? host : process.env.VUE_APP_BACK_URL}/api/notification/making-deal`, {
          onmessage(ev) {
            let data = null
            try {
              data = JSON.parse(ev.data)
              if (data?.error && data.step === 2 && data?.success === 0) {
                self.error = true
                useDealFinishStore().status = 0
                toast.error(`Сделка № ${data?.model}: ${data.message}`, {
                  position: 'bottom-right',
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              } else if (!data?.error && data.step === 2 && data?.success === 1) {
                self.success = true
                useDealFinishStore().status = data.step
                toast.success(`Сделка № ${data?.model}: ${data.message}`, {
                  position: 'bottom-right',
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }
            } catch (e) {
              console.log(e)
            }
          },
          onerror(e) {
            console.log('error ' + e)
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
})
