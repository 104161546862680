import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { DomainRequest } from '@/request/requests/domain.request'
import { request } from '@/request/request'

const toast = useToast()
export const useDomainStore = defineStore('domain-store', {
  state: () => ({
    frontendDomain: null,
    apiDomain: null,
  }),
  actions: {
    async defineDomain() {
      try {
        const isDev = process.env.NODE_ENV === 'development'
        console.log(process.env.NODE_ENV)
        const host = !isDev ? location.host : location.hostname
        const res = await DomainRequest.getDomain({ domain_frontend: host })
        if (res.data?.domain_api) {
          const domainString = !isDev ? `https://${res.data.domain_api}` : `http://${res.data.domain_api}:8000`
          const domainFrontendString = !isDev
            ? `https://${res.data.domain_frontend}`
            : `http://${res.data.domain_frontend}:8080`
          localStorage.setItem('domain_api', domainString)
          localStorage.setItem('domain_frontend', domainFrontendString)
          this.setDomain(domainString)
        }
      } catch (e) {
        console.log(e)
        toast.error(e.response?.data?.message ? `${e.response.data.message}` : `Ошибка получения домена!`, {
          position: 'bottom-right',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      }
    },

    setDomain(domain) {
      request.defaults.baseURL = domain
    },
  },
})
