import { defineStore } from 'pinia'

import { AppConfig } from '@/configs/app.config'
import { Authorization } from '@/request/requests/authorization.request'
import router from '@/router/router'
import { setToken } from '@/utilities/token-utilities'
import { useToast } from 'vue-toastification'

const toast = useToast()

export const useAuthStore = defineStore('authorization', {
  state: () => ({
    token: null,
    role: null,
    createPassword: {
      password: '',
      passwordRepeat: '',
      isPasswordIdentical: true,
      isValid: true,
    },
    login: {
      email: '',
      password: '',
      isEmailValid: true,
      isPasswordValid: true,
    },
    resetPassword: {
      email: '',
      isValid: true,
      canReset: false,
    },
    pinCode: {
      isValid: true,
      isChecked: false,
      isSaveBtnDisabled: true,
      serverError: '',
    },
    showModals: {
      success: false,
      serverError: false,
      recoverySuccess: false,
      pinSuccess: false,
    },
    showTokenError: false,
    showUserError: false,
    isRequestSending: false,
    /*
    у полей 3 типа ошибки:
    1. пустое на фронте
    2. не прошло валидацию на фронте
    3. не прошло проверки на бэке
   */
    errors: {
      email: {
        empty: 'Почта не указана',
        invalid: 'Неправильный формат почты',
        serverError: '',
      },
      password: {
        empty: 'Пароль не указан',
        invalid: 'Пароль не соответствует требованиям',
        serverError: '',
      },
    },
  }),
  actions: {
    setQuery() {
      const route = router.currentRoute.value
      if (route.fullPath.includes('#')) {
        const urlParts = route.fullPath.split('?')
        if (urlParts?.length > 1) {
          const queryParts = urlParts[1]?.split('&')
          if (queryParts?.length) {
            if (queryParts[1]?.startsWith('token=')) this.token = queryParts[1].replace('token=', '')
            if (queryParts[0]?.startsWith('token=')) this.token = queryParts[0].replace('token=', '')
            if (queryParts[1]?.startsWith('role=')) this.role = queryParts[1].replace('role=', '')
            if (queryParts[0]?.startsWith('role=')) this.role = queryParts[0].replace('role=', '')
          }
        }
      } else {
        if (route.query?.token) this.token = route.query.token
        if (route.query?.role) this.role = route.query.role
      }
    },

    async setPassword() {
      try {
        this.isRequestSending = true
        // дополнительно проверим, что введен валидный пароль
        this.checkIsValidPassword()

        if (this.createPassword.password.length && this.createPassword.isValid) {
          const params = {
            token: this.token,
            password: this.createPassword.password,
          }

          if (this.role === 'admin') {
            params.role = this.role
          }

          const res = await Authorization.setUserPassword(params, true)

          if (res.status === 200) {
            this.showModals.success = true
            this.token = ''
          }
        }

        // обнулим данные
        this.createPassword.password = ''
        this.createPassword.passwordRepeat = ''
      } catch (e) {
        this.showTokenError = e.data?.message ?? 'ошибка'
      } finally {
        this.isRequestSending = false
      }
    },
    checkIsValidPassword() {
      this.createPassword.isValid =
        this.isLengthValid && this.hasCorrectSymbols && this.hasUppercase && this.hasLowerCase && this.hasNumber

      // проверяем на идентичность, если были внесены изменения в первое поле после того, как заполнено второе
      if (this.createPassword.passwordRepeat !== '') {
        this.checkIsPasswordIdentical()
      }
    },
    checkIsPasswordIdentical() {
      this.createPassword.isPasswordIdentical = this.createPassword.password === this.createPassword.passwordRepeat
    },
    async redirectAfterCreatePassword() {
      if (this.role === 'admin') {
        window.location.href = `${AppConfig.backUrl}/admin`
      } else {
        await router.push({ name: 'Authorization' })
      }
    },
    async sendLoginRequest() {
      try {
        this.isRequestSending = true

        if (this.login.email.length && this.login.password.length) {
          const params = {
            login: this.login.email,
            password: this.login.password,
            reset_pin: localStorage.getItem('pin') === 'reset',
          }

          const res = await Authorization.loginUser(params, true)

          if (res.status === 200) {
            setToken(res.data.data['api_key'])
            localStorage.setItem('user_role', res.data.data['user_role'])

            this.login.email = ''
            this.login.password = ''

            if (res.data.data['pin_exist']) {
              // редирект на страницу, с которой заходили
              await router.replace({ path: '/' })
            } else {
              // перед редиректом установим флаг в localStorage, чтобы запретить пользователю уходить с этой страницы
              localStorage.setItem('pin', 'create')
              await router.push({ name: 'AuthorizationPinCreate' })
            }
          } else if (res.status === 422) {
            if (res.data.errors.email) {
              this.login.isEmailValid = false
              this.errors.email.serverError = res.data.message
            }
            if (res.data.errors.password) {
              this.login.isPasswordValid = false
              this.errors.password.serverError = res.data.message
            }
          }
        } else {
          this.login.isEmailValid = this.login.email.length > 0
          this.login.isPasswordValid = this.login.password.length > 0
        }
      } catch (e) {
        toast.error(e.data?.message ?? 'Ошибка', {
          position: 'bottom-right',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      } finally {
        this.isRequestSending = false
      }
    },
    async sendResetPasswordRequest() {
      try {
        this.isRequestSending = true

        if (this.resetPassword.email.length) {
          const res = await Authorization.resetUserPassword({ login: this.resetPassword.email }, true)

          if (res.status === 200) {
            this.showModals.recoverySuccess = true
            this.resetPassword.email = ''
          } else {
            this.resetPassword.isValid = false
            this.resetPassword.canReset = false
            this.errors.email.serverError = res.data.message
          }
        } else {
          this.resetPassword.isValid = false
          this.resetPassword.canReset = false
        }
      } catch (e) {
        toast.error(e.data?.message ?? 'Ошибка', {
          position: 'bottom-right',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      } finally {
        this.isRequestSending = false
      }
    },
    async setPinCode(pin) {
      this.pinCode.isSaveBtnDisabled = true

      const params = {
        pin: pin.join(''),
      }

      const res = await Authorization.setUserPin(params)

      if (res.status === 200) {
        localStorage.removeItem('pin')
        this.showModals.pinSuccess = true

        setTimeout(async () => {
          await router.push('/')
          this.showModals.pinSuccess = false
        }, 3000)
      } else {
        this.pinCode.serverError = res.data.message
        await router.push({ name: 'AuthorizationPinCreate' })
      }
    },
  },
  getters: {
    isSaveButtonDisabled() {
      let isDisabled = false
      /*
      флаги isValid и isPasswordIdentical используются для вывода ошибок, меняются по @change
      учитываем их здесь, чтобы кнопка не была активна, пока показываются ошибки
      */
      if (
        this.createPassword.password === '' ||
        this.createPassword.passwordRepeat === '' ||
        this.createPassword.password !== this.createPassword.passwordRepeat ||
        !this.createPassword.isPasswordIdentical ||
        !this.createPassword.isValid ||
        this.isRequestSending
      ) {
        isDisabled = true
      }

      return isDisabled
    },
    isLengthValid() {
      return this.createPassword.password.length >= 8
    },
    hasCorrectSymbols() {
      return this.createPassword.password.length
        ? this.createPassword.password.length === this.createPassword.password.match(/[a-zA-Z0-9]+/)[0]?.length
        : true
    },
    hasUppercase() {
      return this.createPassword.password !== this.createPassword.password.toLowerCase()
    },
    hasLowerCase() {
      return this.createPassword.password !== this.createPassword.password.toUpperCase()
    },
    hasNumber() {
      return this.createPassword.password !== this.createPassword.password.replace(/[0-9]/g, '')
    },
    isLoginButtonDisabled() {
      return (
        this.login.email === '' ||
        !this.login.isEmailValid ||
        this.login.password.length < 8 ||
        !this.login.isPasswordValid ||
        this.isRequestSending
      )
    },
    isPinCodeValid() {
      return false
    },
  },
})
