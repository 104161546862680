import { useStorage } from '@vueuse/core'

const TOKEN_KEY = 'access_token'
const storageState = useStorage(TOKEN_KEY, '')

export function getToken() {
  return storageState.value
}
export function setToken(token) {
  storageState.value = token
}
export function clearToken() {
  storageState.value = null
}
