import { createRouter, createWebHistory, useRoute } from 'vue-router'

import { Deal } from '@/request/requests/deal.request'
import { routes } from '@/router/routes/routes'
import { getToken } from '@/utilities/token-utilities'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from) => {
  const token = getToken()
  /*
  pin=reset -> флаг для переустановки пин-кода при авторизации - функционал "Забыли пин-код"
  pin=create -> флаг для установки пин-кода, приходит от сервера после авторизации
  pin=0303 -> значение пин-кода для страницы подтверждения
   */

  const createPin = localStorage.getItem('pin') && localStorage.getItem('pin') !== 'reset'

  // при необходимости установить пин-код не даем выйти со страниц его создания
  if (createPin && to.name !== 'AuthorizationPinCreate' && to.name !== 'AuthorizationPinConfirm') {
    return { name: 'AuthorizationPinCreate' }
  }

  // при выходе со страниц редактирования отправим запрос на закрытие сессии редактирования
  const editingDealId = localStorage.getItem('editing')

  if (editingDealId && !(to.name === 'DealEdit' || to.name === 'DealQuiz' || to.name === 'DealMenu')) {
    await Deal.stopEdit(editingDealId)
    localStorage.removeItem('editing')
  }

  if ((to.name === 'AuthorizationPinCreate' || to.name === 'AuthorizationPinConfirm') && !createPin) {
    return { name: from.name }
  } else if (
    to.name !== 'Authorization' &&
    to.name !== 'AuthorizationPasswordCreate' &&
    to.name !== 'AuthorizationPasswordReset' &&
    to.name !== 'ErrorIsArchived' &&
    to.name !== 'VisitorQuiz' &&
    !token
  ) {
    return { name: 'Authorization' }
  } else if (
    (to.name === 'Authorization' || to.name === 'AuthorizationPasswordCreate' || to.name === 'VisitorQuiz') &&
    token
  ) {
    return '/'
  }
})

export default router
