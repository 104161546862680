export const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Deals',
        component: () => import('@/pages/Deals/Deals.vue'),
      },
      {
        path: 'deal/:id',
        name: 'Deal',
        component: () => import('@/pages/Deal/Deal.vue'),
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('@/pages/Clients/Clients.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('@/pages/Products/Products.vue'),
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('@/pages/Reports/Reports.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'AuthorizationLayout',
    component: () => import('@/layouts/AuthorizationLayout.vue'),
    children: [
      {
        path: '',
        name: 'Authorization',
        component: () => import('@/pages/Authorization/Authorization.vue'),
      },
      {
        path: 'create-password',
        name: 'AuthorizationPasswordCreate',
        component: () => import('@/pages/Authorization/AuthorizationPassword/AuthorizationPasswordCreate.vue'),
      },
      {
        path: 'create-pin',
        name: 'AuthorizationPinCreate',
        component: () => import('@/pages/Authorization/AuthorizationPin/AuthorizationPinCreate.vue'),
      },
      {
        path: 'confirm-pin',
        name: 'AuthorizationPinConfirm',
        component: () => import('@/pages/Authorization/AuthorizationPin/AuthorizationPinConfirm.vue'),
      },
      {
        path: 'reset-password',
        name: 'AuthorizationPasswordReset',
        component: () => import('@/pages/Authorization/AuthorizationPassword/AuthorizationPasswordReset.vue'),
      },
    ],
  },
  {
    path: '/deal',
    name: 'DealPagesLayout',
    component: () => import('@/layouts/DealPagesLayout.vue'),
    children: [
      {
        path: ':id/quiz',
        name: 'DealQuiz',
        component: () => import('@/pages/Deal/DealQuiz.vue'),
      },
      {
        path: ':id/menu',
        name: 'DealMenu',
        component: () => import('@/pages/Deal/DealMenu.vue'),
      },
      {
        path: 'create',
        name: 'DealCreate',
        component: () => import('@/pages/Deal/DealCreate/DealCreate.vue'),
      },
      {
        path: ':id/edit',
        name: 'DealEdit',
        component: () => import('@/pages/Deal/DealEdit/DealEdit.vue'),
      },
      {
        path: ':id/finish',
        name: 'DealFinish',
        component: () => import('@/pages/Deal/DealFinish/DealFinish.vue'),
      },
    ],
  },
  {
    path: '/error-archived',
    name: 'ErrorIsArchived',
    component: () => import('@/pages/Errors/ErrorIsArchived.vue'),
  },
  {
    path: '/error-:id',
    name: 'Error',
    component: () => import('@/pages/Errors/Error.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/error-404',
  },
  {
    path: '/visitor',
    name: 'VisitorLayout',
    component: () => import('@/layouts/VisitorLayout.vue'),
    children: [
      {
        path: 'quiz/:dealid?/:accessToken',
        name: 'VisitorQuiz',
        component: () => import('@/pages/Visitor/VisitorQuiz.vue'),
      },
    ],
  },
]
