import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { Deal } from '@/request/requests/deal.request'
import router from '@/router/router'

const toast = useToast()

export const useDealFinishStore = defineStore('deal-finish', {
  state: () => ({
    isLoadingDealFinish: false,
    isLoadingSaveDealFinish: false,
    status: null,
    errors: [],
    data: null,
    oldVersionData: null,
  }),

  actions: {
    getFormData() {
      const deal_info = {}
      if (this.data.total_information?.is_add_agreement) {
        deal_info.is_add_agreement = this.data.total_information?.is_add_agreement
      }

      const car = {
        id: this.data.total_information.car?.id,
      }
      if (this.data.total_information.car?.gos_number || this.data.total_information.car?.gos_number === '') {
        car.gos_number = this.data.total_information.car.gos_number
      }
      if (this.data.total_information.car?.color || this.data.total_information.car?.color === '') {
        car.color = this.data.total_information.car.color
      }
      if (this.data.total_information.car?.vin || this.data.total_information.car?.vin === '') {
        car.vin = this.data.total_information.car.vin
      }
      if (this.data.total_information.car?.valid_factory_warranty) {
        car.valid_factory_warranty = this.data.total_information.car.valid_factory_warranty
      }
      if (this.data.total_information.car?.garant_factory_end_date) {
        car.garant_factory_end_date = this.data.total_information.car.garant_factory_end_date
          .split('.')
          .reverse()
          .join('-')
      }
      if (this.data.total_information.car?.garant_factory_end_date === '') {
        car.garant_factory_end_date = this.data.total_information.car.garant_factory_end_date = ''
      }

      const client = {
        id: this.data.total_information.client?.id,
        insurer_type: 'F',
      }
      if (this.data.total_information.client?.name) client.name = this.data.total_information.client.name
      if (this.data.total_information.client?.last_name) client.last_name = this.data.total_information.client.last_name
      if (this.data.total_information.client?.middle_name || this.data.total_information.client?.middle_name === '')
        client.middle_name = this.data.total_information.client?.middle_name
      if (this.data.total_information.client?.birth_date || this.data.total_information.client?.birth_date === '')
        client.birth_date = this.data.total_information.client.birth_date.split('.').reverse().join('-')
      if (this.data.total_information.client?.birth_year || this.data.total_information.client?.birth_year === '')
        client.birth_year = this.data.total_information.client.birth_year
      if (this.data.total_information.client?.phone || this.data.total_information.client?.phone === '')
        client.phone = this.data.total_information.client.phone.replace(/\D/g, '').substring(1)
      if (this.data.total_information.client?.email || this.data.total_information.client?.email === '')
        client.email = this.data.total_information.client.email
      if (this.data.total_information.passport?.doc_series || this.data.total_information.passport?.doc_series === '')
        client.doc_series = this.data.total_information.passport.doc_series
      if (this.data.total_information.passport?.doc_num || this.data.total_information.passport?.doc_num === '')
        client.doc_num = this.data.total_information.passport.doc_num
      if (this.data.total_information.passport?.doc_who || this.data.total_information.passport?.doc_who === '')
        client.doc_who = this.data.total_information.passport.doc_who
      if (this.data.total_information.passport?.doc_date || this.data.total_information.passport?.doc_date === '')
        client.doc_date = this.data.total_information.passport?.doc_date.split('.').reverse().join('-')
      if (this.data.total_information.address?.index_cust || this.data.total_information.address?.index_cust === '')
        client.index_cust = this.data.total_information.address.index_cust
      if (this.data.total_information.address?.country_cust || this.data.total_information.address?.country_cust === '')
        client.country_cust = this.data.total_information.address.country_cust
      if (this.data.total_information.address?.region_cust || this.data.total_information.address?.region_cust === '')
        client.region_cust = this.data.total_information.address.region_cust
      if (
        this.data.total_information.address?.district_cust ||
        this.data.total_information.address?.district_cust === ''
      )
        client.district_cust = this.data.total_information.address.district_cust
      if (this.data.total_information.address?.point_cust || this.data.total_information.address?.point_cust === '')
        client.point_cust = this.data.total_information.address.point_cust
      if (this.data.total_information.address?.street_cust || this.data.total_information.address?.street_cust === '')
        client.street_cust = this.data.total_information.address.street_cust
      if (this.data.total_information.address?.house_cust || this.data.total_information.address?.house_cust === '')
        client.house_cust = this.data.total_information.address.house_cust
      if (this.data.total_information.address?.flat_cust || this.data.total_information.address?.flat_cust === '')
        client.flat_cust = this.data.total_information.address.flat_cust

      const formData = {
        deal_info: deal_info,
        car: car,
        client: client,

        products: this.data.products.data.map((el) => {
          const obj = {
            id: el.id,
          }
          if (el?.product_type) obj.product_type = el.product_type
          if (el?.card_date) obj.card_date = el.card_date
          if (el?.duration) obj.duration = el.duration
          if (el?.discount) obj.discount = el.discount
          if (el?.name) obj.name = el.name
          if (el?.package_discount) obj.package_discount = el.package_discount
          if (el?.price) obj.price = el.price
          if (el?.series) obj.series = el.series
          if (el?.number) obj.number = el.number
          if (el?.use_from) obj.use_from = el.use_from
          if (el?.use_to) obj.use_to = el.use_to
          return obj
        }),
      }

      if (this.data.total_information.banks?.selected_bank_id) {
        formData.bank_external_id = this.data.total_information.banks.selected_bank_id?.id
      }

      if (this.data.total_information.cities?.selected_cities_id) {
        formData.cities_external_id = this.data.total_information.cities.selected_cities_id?.id
      }

      if (this.data.total_information.payment_place?.payment_place_id) {
        formData.deal_info.payment_place_id = this.data.total_information.payment_place?.payment_place_id?.id
      }

      return formData
    },

    async fetchFinishData(id) {
      try {
        this.isLoadingDealFinish = true
        const res = await Deal.fetchFinishData(id, true)
        if (res) {
          this.data = res.data
          this.oldVersionData = structuredClone(res.data)
        }
      } catch (e) {
        toast.error(`Ошибка: ${e.status}`, {
          position: 'bottom-right',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      } finally {
        this.isLoadingDealFinish = false
      }
    },
    async saveFinishData(id) {
      try {
        this.isLoadingSaveDealFinish = true

        const res = await Deal.saveFinishData(id, this.getFormData(), true)

        if (res) {
          await router.push({ name: 'Deal', params: { id } })
          toast.success(`${res?.data}`, {
            position: 'bottom-right',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      } catch (e) {
        if (e?.data?.errors && Object.keys(e?.data?.errors)?.length) {
          this.errors = e.data.errors
        } else {
          toast.error(`Ошибка: ${typeof e.data === 'string' ? e.data : e.data?.message}`, {
            position: 'bottom-right',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      } finally {
        this.isLoadingSaveDealFinish = false
      }
    },
    async sendFinishData(id) {
      try {
        this.isLoadingSaveDealFinish = true
        const res = await Deal.saveAndSendFinishData(id, this.getFormData(), true)
        if (res) {
          this.status = res.data.step
          toast.success(`${res?.data.message}`, {
            position: 'bottom-right',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      } catch (e) {
        this.status = 0
        if (e?.data?.errors && Object.keys(e?.data?.errors)?.length) {
          this.errors = e.data.errors
        } else {
          toast.error(`Ошибка: ${e.data.error}`, {
            position: 'bottom-right',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      } finally {
        this.isLoadingSaveDealFinish = false
      }
    },
  },
})
