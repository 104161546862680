import 'vue-toastification/dist/index.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

import vClickOutside from 'click-outside-vue3'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueTippy from 'vue-tippy'
import Toast from 'vue-toastification'

import App from './App.vue'
import router from './router/router'

const pinia = createPinia()

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(vClickOutside)
app.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
})
app.use(VueTippy, {
  directive: 'tippy',
  component: 'tippy',
  theme: 'light-border',
  componentSingleton: 'tippy-singleton',
  defaultProps: {
    placement: 'top',
    allowHTML: false,
    trigger: 'mouseenter click',
  },
})
app.mount('#app')
