import { request } from '@/request/request'

export class Authorization {
  static async setUserPassword(params, bubbleError = false) {
    return await request.post('/api/auth/password', params, { bubbleError })
  }

  static async loginUser(params, bubbleError = false) {
    return await request.post('/api/auth/login', params, { bubbleError })
  }

  static async resetUserPassword(params, bubbleError = false) {
    return await request.post('/api/auth/reset-password', params, { bubbleError })
  }

  static async setUserPin(params) {
    return await request.post('/api/auth/pin', params)
  }

  static async resetUserPin(params) {
    return await request.post('/api/auth/reset-pin', params)
  }

  static async logoutUser() {
    return await request.post('/api/auth/logout', {})
  }
}
