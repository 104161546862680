<template>
  <router-view />
</template>

<script>
import { computed, defineComponent } from 'vue'

import { useNotificationStore } from '@/store/modules/notification.store'
import { useDomainStore } from '@/store/pages/domain.store'
import { getToken } from '@/utilities/token-utilities'

export default defineComponent({
  name: 'App',
  setup() {
    const domainStore = useDomainStore()
    const domain = localStorage.getItem('domain_api')
    const domainFrontend = localStorage.getItem('domain_frontend')
    if (!domain || !domainFrontend) domainStore.defineDomain()
    else domainStore.setDomain(domain)

    const accessToken = computed(() => {
      return getToken()
    })

    if (accessToken.value && process.env.NODE_ENV === 'production') {
      useNotificationStore().subDealFinish(accessToken.value)
    }
  },
})
</script>

<style lang="scss">
@import '~@/assets/style/fonts.scss';
@import '~@/assets/style/general.scss';

@import '~@/assets/style/blocks/section-top.scss';
@import '~@/assets/style/ui-elements/badge-status.scss';

@import '~@/assets/style/ui-elements/deal-packages.scss';
@import '~@/assets/style/ui-elements/form-field.scss';
</style>
